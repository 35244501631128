.my-dd-container {
    position: relative;
}

.my-dd-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 8px 12px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    /* background-color: #fff; */
}

.my-dd-selected:hover {
    background-color: #4f46e5;
}

.my-dd-disabled {
    cursor: not-allowed;
    background-color: #f3f3f3;
}

.my-dd-arrow {
    width: 0;
    height: 0;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
}

.my-dd-open {
    border-bottom: 5px solid #fff;
    border-top: none;
}

.my-dd-options {
    position: absolute;
    z-index: 100;
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    min-width: 120px;
    background-color: #fff;
    overflow-y: auto;
    max-height: 200px;
    box-shadow: -2px 0 15px rgba(0, 0, 0, .14);
    top: calc(100% + 4px);
    padding: 4px 0;
}

.my-dd-options-right {
    left: 0;
}

.my-dd-options-left {
    right: 0;
}


.my-dd-search {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
}

.my-dd-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* border-bottom: 1px solid #ccc; */
}

.my-dd-option:last-child {
    border-bottom: none;
}

.my-dd-option:hover {
    background-color: #eef2ff;
}

.my-dd-selected-option {
    color: #6366f1;
    background-color: #e0e7ff;
}

.my-dd-selected-option:hover {
    color: #6366f1;
    background-color: #e0e7ff;
}

.my-dd-checkmark {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #000;
    border-radius: 50%;
    background-color: #000;
}