#map{
    height: 100vh;
    width:100%;
    }
    .Map_info{
    color:#000;
    }
    .text-dark{
    color:#000;
    font-weight:700;
    }
    /* button.gm-ui-hover-effect {
    visibility: hidden;
    } */
    .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #040C1E;;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    }
    .sidenav .closebtn {
    /* position: absolute;
    top: 0;
    left: 25px; */
    font-size: 26px;
    margin-left:27px;
    }
    .bottom-fix{
    }
    .f-800{
    font-weight:800 !important;
    }
    /* .form-group{
    margin:0px;
    } */
    .form-group .control-label, .form-group > label{
    margin:0px;
    }
    .card-type{
    border-left:5px solid #040C1E;;
    border-right:5px solid #040C1E;;
    }
    .gateway_link{
       cursor: pointer;
    }
.filter-btn{
   position: fixed;
    z-index: 111;
    right: 0;
    top: 60px;
    margin-right: 9px;
}
.setting-btn{
   position: fixed;
   z-index: 111;
   right: 0;
   top: 110px;
   margin-right: 9px;
}

#map{
   height: 100vh;
   width:100%;
   }
   .Map_info{
   color:#000;
   }
   .text-dark{
   color:#000;
   font-weight:700;
   }
   /* button.gm-ui-hover-effect {
   visibility: hidden;
   } */
   .map_filter {
   height: 100%;
   width: 0;
   position: fixed;
   z-index: 1;
   top: 0;
   left: 0;
   background-color: #fff;;
   overflow-x: hidden;
   transition: 0.5s;
   padding-top: 20px;
   }
   .map_filter .closebtn {
   /* position: absolute;
   top: 0;
   left: 25px; */
   font-size: 26px;
   margin-left:27px;
   }
   .bottom-fix{
   }
   .f-800{
   font-weight:800 !important;
   }
   /* .form-group{
   margin:0px;
   } */
   .form-group .control-label, .form-group > label{
   margin:0px;
   }
   .card-type{
   border-left:5px solid #040C1E;;
   border-right:5px solid #040C1E;;
   }
   .gateway_link{
      cursor: pointer;
   }