.my-modal-class .ant-modal-body {
    height: 80vh;
    overflow: auto;
    padding: 0 !important;

}

.info_circle {
    font-size: 18px;
    color: #6777ef;
    cursor: pointer;
}

.dropdown-menu.dropleft.show {
    left: -50px !important;
}

.my-modal-class .modal-xl{
    max-width:1350px !important;
}