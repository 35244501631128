.cron_builder{
    max-width: 69% !important;
}

.cron-builder-bg{
    background-color: #919ae7  !important;
}


@keyframes fadeInRightGrow {
    from {
        width: 100%;
        /* -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0); */
    }

    to {
        width: 66.666667%
            /* -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
    }
}

.cron-animate__fadeInRight_grow {
    /* -webkit-animation-name: fadeInRight; */
    animation: fadeInRightGrow 0.5s;
}

.dropdown-toggle::after{
    display: none !important;
}