.ant-popover-title {
  display: none;
}

.user-popover.ant-popover {
  padding: 8px 0px 0px 0px  !important;
  z-index: 10 !important;
}


.user-popover > .ant-popover-content > .ant-popover-inner {
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow:  0 -1px 6px -1px rgb(0 0 0 / 0.1),  0 -2px 4px -2px rgb(0 0 0 / 0.1), 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.user-popover > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content{
  padding: 0px !important;

}

.border-bottom-dahsed {
  border-bottom: 1px dashed #8b8cf0;
}



.task-details__info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  margin-top: 1.25rem;
}

.task-detail__info {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  gap: 8px;
  border-bottom: 1px solid #e5e5e6;
  transition: border-bottom 0.2s ease-in-out;
}

.task-detail__info:hover {
  border-bottom: 1px solid #a5b4fc;
}

.task-detail__info-label {
  font-weight: 600;
  margin-right: 0.5rem;
  width: 25%;
}

.task-detail__info-value {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  width: 75%;
}

.task-details {
border: 1px solid #e5e5e6;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
animation-duration: 0.5s;
animation-name: slide-in;
animation-fill-mode: forwards;
}