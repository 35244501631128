@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&family=Source+Code+Pro:wght@200;300;400;500;600;700&display=swap');


:root {
    --accent-color: #6366f1;
}


h1 {
    margin: 0 0 20px 0;
    /* color: var(--accent-color); */
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0 10px 0;
}

p {
    margin: 0 0 20px 0;
    line-height: 1.5;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 40px 0;
}

pre {
    font-family: 'Nunito', sans-serif;
    font-family: 'Source Code Pro', monospace;
    background-color: #f7f7f7;
    padding: 10px;
    font-size: 14px;
    overflow: auto;
    border-radius: 2px;
    margin: 20px 0;
    line-height: 1.5;
}

code {
    font-family: 'Source Code Pro', monospace;
}

/* API documentation styles */


.api-title {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.api-purpose {
    margin: 10px 0 20px 0;
}

.api-method {
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
}

.api-auth {
    margin: 10px 0;
}

.api-input-params {
    margin: 20px 0;
}

.api-input-params th,
.api-input-params td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.api-input-params th {
    background-color: #f7f7f7;
}

.api-output {
    margin: 20px 0;
}

.api-example {
    margin: 20px 0;
}

.api-example-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.api-example-request {
    margin: 10px 0;
}

.api-example-response {
    margin: 10px 0;
}