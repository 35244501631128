.my-dropdown {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    /* background-color: #fff; */

}

.my-dropdown-container {
    position: relative;
    display: inline-block;
}

.my-dropdown__toggle {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.my-dropdown__toggle:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.my-dropdown__arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #333 transparent transparent transparent;

}

.my-dropdown__arrow--up {
    transform: rotate(-135deg);
}


.my-dropdown__menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 15;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: -2px 0 15px rgba(0, 0, 0, .14);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
}

.my-dropdown__menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background-color: rgb(255, 255, 255);
    border: rgba(235, 235, 235, 0.8) solid 1px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    border-right: 0;
    border-bottom: 0;
    top: -7px;
    left: 15px;
}

.my-dropdown__menu--open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    padding: 2px 0px;
}

.my-dropdown__menu--transition-enter {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
}

.my-dropdown__menu--transition-enter-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 300ms;
}

.my-dropdown__menu--transition-exit {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.my-dropdown__menu--transition-exit-active {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 300ms;
}

.my-dropdown__search {
    margin: 4px 12px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 4px;
    border-bottom: 1px solid #4f46e5;
}

.my-dropdown__search input[type='text'] {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: 16px;
    color: #333;
    background-color: transparent;
}

.my-dropdown__options {
    /* max-height: 200px; */
    overflow-y: auto;
    margin: 4px 0px;
    text-align: start;
}

.my-dropdown__option:hover {
    color: #4f46e5;
}

.my-dropdown__option-container:hover {
    background-color: #e0e7ff !important;
}

.my-dropdown__option {
    display: block;
    padding: 0.25rem 0.75rem;
    cursor: pointer;
    font-size: 16px;
}

.my-dropdown__option--selected {
    font-weight: 600;
    color: #4f46e5;
}

.my-dropdown__option--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.my-dropdown__no-options {
    padding: 0.25rem 0.75rem;
    color: #999;
}

@media (max-width: 767px) {
    .my-dropdown__menu {
        max-height: 150px;
    }
}

.my-dropdown__option-circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
    /* adjust as needed */
}