.my-tabs {
    position: relative;
}

.my-tabs .my-tabs-header {
    position: relative;
}

.my-tabs .my-tabs-header .my-tabs-items {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 10px;
}


.my-tabs .my-tabs-header .my-tabs-items .my-tabs-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: medium;
}

.my-tabs .my-tabs-header .my-tabs-items .my-tabs-item.active {
    font-weight: 500;
    border-radius: 1rem;

}


.my-tabs .my-tabs-header .my-tabs-active-item {
    display: no;
    height: 2px;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: left, width;
}

.my-tabs .my-tabs-content .my-tabs-content-item {
    display: none;
}

.my-tabs .my-tabs-content .my-tabs-content-item.show {
    display: block;
}