.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    column-gap: 10px;
}

.wrapper .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #008ae6;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: animate 0.5s ease-in-out infinite alternate;
}

.dot:nth-child(1) {
    animation-delay: -0.25s;
}

.dot:nth-child(2) {
    background: #e60000;
    animation-delay: -0.5s;
}

.dot:nth-child(3) {
    background: #ffcc00;
    animation-delay: -0.75s;
}

.dot:nth-child(4) {
    background: #008800;
    animation-delay: -1s;
}

@keyframes animate {
    0% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(5px);
    }
}