.ant-popover-title {
  display: none;
}

.ant-popover {
  padding: 8px 0px 0px 0px !important;
  z-index: 2000 !important;
}



.ant-popover-inner {
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow:  0 -1px 6px -1px rgb(0 0 0 / 0.1),  0 -2px 4px -2px rgb(0 0 0 / 0.1), 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.border-bottom-dahsed {
  border-bottom: 1px dashed #6366F1;
}