.btn-cssbuttons {
    --btn-color: #6366f1;
    position: relative;
    padding: 8px 12px;
    color: white;
    background: none;
    border: none;
    outline: none;
    overflow: hidden;
    cursor: pointer;
    filter: drop-shadow(0 2px 8px rgba(39, 94, 254, 0.32));
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons::before {
    position: absolute;
    content: "";
    padding: 8px 8px;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--btn-color);
    border-radius: 24px;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons span,
.btn-cssbuttons span span {
    display: inline-flex;
    vertical-align: middle;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons span {
    transition-delay: 0.05s;
}


.btn-cssbuttons span span {
    margin-left: 8px;
    transition-delay: 0.1s;
}

.btn-cssbuttons ul {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    transform: translateY(-50%);
}

.btn-cssbuttons ul li {
    flex: 1;
}

.btn-cssbuttons ul li div {
    display: inline-flex;
    vertical-align: middle;
    transform: translateY(55px);
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons ul li div:hover {
    opacity: 0.5;
}

.btn-cssbuttons:hover::before {
    transform: scale(1.2);
}

.btn-cssbuttons:hover span,
.btn-cssbuttons:hover span span {
    transform: translateY(-55px);
}

.btn-cssbuttons:hover ul li div {
    transform: translateY(0);
}

.btn-cssbuttons:hover ul li:nth-child(1) div {
    transition-delay: 0.15s;
}

.btn-cssbuttons:hover ul li:nth-child(2) div {
    transition-delay: 0.2s;
}