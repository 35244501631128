.multi-input {
    display: inline-block;
    position: relative;
    border: 1px solid rgb(199 210 254);
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 330px;
    box-shadow: 0 2px 4px 0 rgba(99, 210, 254, 0.15)
}

.multi-input__items {
    display: flex;
    align-items: center;
    padding: 4px;
    position: relative;
}

.multi-input__item {
    background-color: #e7e5e4;
    border-radius: 4px;
    padding: 0px 4px;
    margin: 2px;
    padding-right: 0;
    display: flex;
    align-items: center;
}

.multi-input__item-value {
    margin-right: 2px;
    /* font-size: 85%; */
}

.multi-input__item-clear {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    margin-left: 0px;
    padding-left: 0.275rem;
    padding-right: 0.275rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
}

.multi-input__item-clear:hover {
    color: #ff5a5a;
}

.multi-input__input {
    outline: none;
    box-sizing: border-box;
    border: none;
    font-size: 14px;
    background-color: transparent;
    border-radius: 4px;
    padding: 2px 6px !important;
    margin: 2px !important;
    padding-right: 0;
    width: auto;
    min-width: 50px;
    flex:1;
}

.multi-input__input:focus {
    outline: none;
}



.multi-input__clear-all {
    cursor: pointer;
    font-weight: bold;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.multi-input__clear-all:hover {
    color: rgb(239 68 68);
}

.multi-input__warning {
    color: #ff5a5a;
    font-size: 12px;
    margin-top: 4px;
}

.multi-input__input-wrapper {
    position: relative;
    display: inline-block;
    max-width: 100%;
}

.multi-input__warning-tooltip {
    visibility: hidden;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.multi-input__input-wrapper:hover .multi-input__warning-tooltip {
    visibility: visible;
    opacity: 1;
}