.chart-loader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 60px;
    width: 70px;
    padding: 5px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
}

.chart-loader-bar {
    height: 6px;
    width: 12px;
    background-color: #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    animation: chart-loader 2s infinite;
}

.chart-loader-bar-1 {
    animation-delay: 0.25s;
}

.chart-loader-bar-2 {
    animation-delay: 0.5s;
}

.chart-loader-bar-3 {
    animation-delay: 0.75s;
}

.chart-loader-bar-4 {
    animation-delay: 1s;
}

@keyframes chart-loader {
    0% {
        background-color: #67b7dc;
    }

    30% {
        background-color: #6771dc;
    }

    50% {
        height: 50px;
    }

    80% {
        background-color: #a367dc;
    }

    100% {
        background-color: #dc67ce;
    }
}