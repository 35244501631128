@keyframes fadeInRight_api {
    from {
        visibility: visible;
        width: 0;
        /* -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0); */
    }

    to {
        width: 70%;
        /* -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
    }
}

.animate__fadeInRight_api {
    /* -webkit-animation-name: fadeInRight; */
    animation: fadeInRight_api 0.5s;
}