.otpContainer {
    margin: 5% auto;
}

.otpInput {
    width: 2rem !important;
    height: 2rem;
    margin: 0 0.75rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.otp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
}

.code-wrapper {
    /* border: 1px solid #ddd; */
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    border-radius: 4px;
}

.code-wrapper .code {
    padding: 5px;
    width: 2rem;
    /* border-bottom: 2px solid #aeaeae; */
    border: 1px solid #aeaeae;
    border-radius: 4px;
    text-shadow: 0 0 0 #101010;
    text-align: center;
    font-size: 1rem;
    outline: none;
    background: none;

}

.code-wrapper .code.focus {
    border: 1px solid #101010;
    /* border-bottom: 2px solid #101010; */
}

.code-wrapper .code:not(:last-child) {
    margin-right: 0px;

}

.code-wrapper .code::selection {
    background: none;
}